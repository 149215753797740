import { template as template_b3cc4dd2c0694a1b9609bd9f60143076 } from "@ember/template-compiler";
const WelcomeHeader = template_b3cc4dd2c0694a1b9609bd9f60143076(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

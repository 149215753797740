import { template as template_4b3103deb4c34c548d6c498562b9f116 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4b3103deb4c34c548d6c498562b9f116(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

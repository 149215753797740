import { template as template_8d81d31d72bf45cd94486113022a20ad } from "@ember/template-compiler";
const FKLabel = template_8d81d31d72bf45cd94486113022a20ad(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
